<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-3">
            <c-label-text title="순번" :value="popupParam.jobStepNo"></c-label-text>
          </div>
          <div class="col-9">
            <c-label-text title="작업단계명" :value="popupParam.jobStepName"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="유해위험요인"
        :isTitle="true"
        :columnSetting="false"
        :usePaging="false"
        :filtering="false"
        :gridHeightAuto="true"
        :hideBottom="true"
        :columns="grid.columns"
        :data="grid.data"
      >
        <template v-slot:customArea="{ props }">
          <template>
            <q-btn
              flat
              size="12px"
              color="orange"
              icon="chevron_right"
              @click="rowClickDetail(props.row, props.pageIndex)" />
          </template>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <q-dialog v-model="dialogDetail" :position="positionDetail">
      <q-card style="width: 100%">
        <q-linear-progress :value="1" color="pink" />
        <q-card-section class="row">
          <div class="col-12 text-weight-bold-dailog" v-text="selectedRow.riskHazardName"></div>
          <div class="col-4"><div class="mini-dailog-title">{{ $language('원인') }}</div></div>
          <div class="col-8" v-text="selectedRow.hazardOccurReason"></div>
          <div class="col-4"><div class="mini-dailog-title">{{ $language('결과') }}</div></div>
          <div class="col-8" v-text="selectedRow.hazardOccurResult"></div>
          <div class="col-4"><div class="mini-dailog-title">{{ $language('관련 사진') }}</div></div>
          <div class="col-8">
            <q-icon style="font-size:30px"
              class="text-primary" 
              name="image"
              @click.stop="openPicture(selectedRow)" />
          </div>
          <div class="col-12 mini-dailog-btn-impr">
            <q-btn-group outline>
              <c-btn
                :showLoading="false"
                label="닫기"
                icon="close"
                @btnClicked="dialogClose"
              />
            </q-btn-group>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'sop-detail-risk-hazard',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        jobStepId: '',
      }),
    },
  },
  data() {
    return {
      dialogDetail: false,
      positionDetail: 'bottom',
      selectedRow: {},
      selectedRowIdx: -1,
      grid: {
        // merge: [
        //   { index: 0, colName: 'riskHazardName' },
        //   { index: 1, colName: 'riskHazardName' },
        // ],
        columns: [
          {
            name: 'ramRiskHazardClassName',
            field: 'ramRiskHazardClassName',
            label: '유해위험요인분류',
            align: 'center',
            style: 'width:140px',
            sortable: false,
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            label: '유해위험요인',
            align: 'center',
            sortable: false,
            // innerBtn: true,
            // btns: [
            //   { icon: 'add', }
            // ]
          },
          // {
          //   name: 'hazardOccurReason',
          //   field: 'hazardOccurReason',
          //   label: '원인',
          //   align: 'left',
          //   style: 'width:150px',
          //   type: 'textarea',
          //   sortable: false,
          // },
          // {
          //   name: 'hazardOccurResult',
          //   field: 'hazardOccurResult',
          //   label: '결과',
          //   align: 'left',
          //   style: 'width:150px',
          //   type: 'textarea',
          //   sortable: false,
          // },
          // {
          //   name: 'picture',
          //   field: 'picture',
          //   label: '유해위험요인<br/>사진',
          //   align: 'center',
          //   type: 'custom',
          //   style: 'width:90px',
          //   sortable: false,
          // },
          {
            name: 'customDetail',
            field: 'customDetail',
            label: '',
            align: 'center',
            type: 'custom',
            style: 'width:40px',
            sortable: false
          },
        ],
        height: '500px',
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  watch: {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.sop.jobhazardreason.url;
      this.getList();
    },
    getList() {
      if (this.popupParam.jobStepId) {
        this.$http.url = this.detailUrl;
        this.$http.type = 'GET';
        this.$http.param = {mdmSopId: this.popupParam.mdmSopId, jobStepId: this.popupParam.jobStepId};
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    rowClickDetail(_row, _idx) {
      this.dialogDetail = true;
      this.selectedRow = _row;
      this.selectedRowIdx = _idx;
    },
    dialogClose() {
      this.dialogDetail = false;
    },
    openPicture(row) {
      this.popupOptions.title = '유해위험요인 사진';
      this.popupOptions.param = {
        ramRiskHazardId: row.mdmHazardFactorsId,
      };
      this.popupOptions.target = () => import(`${'./jobRiskHazardPicture.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePicturePopup;
    },
    closePicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>