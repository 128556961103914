var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            { staticClass: "cardClassDetailInfo", attrs: { noHeader: true } },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: { title: "순번", value: _vm.popupParam.jobStepNo },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-9" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "작업단계명",
                        value: _vm.popupParam.jobStepName,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "table",
            attrs: {
              title: "유해위험요인",
              isTitle: true,
              columnSetting: false,
              usePaging: false,
              filtering: false,
              gridHeightAuto: true,
              hideBottom: true,
              columns: _vm.grid.columns,
              data: _vm.grid.data,
            },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props }) {
                  return [
                    [
                      _c("q-btn", {
                        attrs: {
                          flat: "",
                          size: "12px",
                          color: "orange",
                          icon: "chevron_right",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.rowClickDetail(
                              props.row,
                              props.pageIndex
                            )
                          },
                        },
                      }),
                    ],
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c(
        "q-dialog",
        {
          attrs: { position: _vm.positionDetail },
          model: {
            value: _vm.dialogDetail,
            callback: function ($$v) {
              _vm.dialogDetail = $$v
            },
            expression: "dialogDetail",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { width: "100%" } },
            [
              _c("q-linear-progress", { attrs: { value: 1, color: "pink" } }),
              _c("q-card-section", { staticClass: "row" }, [
                _c("div", {
                  staticClass: "col-12 text-weight-bold-dailog",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.riskHazardName),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v(_vm._s(_vm.$language("원인"))),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-8",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.hazardOccurReason),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v(_vm._s(_vm.$language("결과"))),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-8",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.hazardOccurResult),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v(_vm._s(_vm.$language("관련 사진"))),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("q-icon", {
                      staticClass: "text-primary",
                      staticStyle: { "font-size": "30px" },
                      attrs: { name: "image" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.openPicture(_vm.selectedRow)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 mini-dailog-btn-impr" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: {
                            showLoading: false,
                            label: "닫기",
                            icon: "close",
                          },
                          on: { btnClicked: _vm.dialogClose },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }